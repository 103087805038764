<h1>Bonus</h1>

<div>

  <div class="card-deck m-3">

    <div class="card bg-light">
      <div class="card-header">
        Vælg data
      </div>
      <div class="card-body">

        <div *ngIf="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>

        <form onsubmit="return false">
          <fieldset [disabled]="loading">
            <div class="form-group">
              <label for="aar">
                Optjeningsperiode
                <mn-info-icon title="Optjeningsperiode">
                  Perioden hvor den udbetalte bonus er optjent. Bonussen vil typisk fremgå af efterfølgende måneds lønseddel. Fx vil bonus for april udbetales med maj lønnen.
                </mn-info-icon>
              </label>
              <div class="input-group">
                <select name="aar" id="aar" class="form-control" title="År" [(ngModel)]="aar">
                  <option *ngFor="let x of getArrList()">{{x}}</option>
                </select>
                <select name="maaned" class="form-control" title="Måned" [(ngModel)]="maaned">
                  <option *ngFor="let x of getMaanedList()" value="{{x}}">{{x | monthName}}</option>
                </select>
              </div>
            </div>
            <button (click)="hentData()" class="btn btn-primary">Hent data</button>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>

<div>

  <div *ngIf="loading" style="text-align: center;">
    <i class="fa fa-spinner fa-spin" style="font-size:24px"></i> Indlæser data...
  </div>

  <div *ngIf="bonus && !loading && !error">

    <div class="card-deck m-3">

      <div class="card bg-light">
        <div class="card-header">
          Bonusberegning
          <mn-info-icon title="Bonusberegning">
            Her ses hvorledes bonussen for perioden er udregnet. Klik på linjer for yderligere detaljer
          </mn-info-icon>
        </div>

        <div class="card-body">

          <div class="bonus-div">

            <div class="bonus-line-wrapper bonus-unfolded">
              <div class="bonus-text" (click)="toggleFolded($event)">
                Udbetalt bonus
                <mn-info-icon title="Udbetalt bonus">
                  Totalbonus, som er udbetalt sammen med lønnen.
                </mn-info-icon>
                <button class="unfoldable-icon">Fold ind</button>
              </div>
              <div class="bonus-foldable">

                <div class="bonus-line-wrapper">
                  <div class="bonus-text" (click)="toggleFolded($event)">
                    Timebonus
                    <mn-info-icon title="Timebonus">
                      Bonusbeløb, baseret på timer
                    </mn-info-icon>
                    <button class="unfoldable-icon">Fold ind</button>
                  </div>
                  <div class="bonus-foldable">
                    <div class="bonus-line-wrapper">
                      <div class="bonus-text" (click)="toggleFolded($event)">
                        Udbetalte timer
                        <mn-info-icon title="Udbetalte timer">
                          Det antal timer, som bruges til at udregne bonusudbetalingen.
                        </mn-info-icon>
                        <button class="unfoldable-icon">Fold ind</button>
                      </div>
                      <div class="bonus-foldable">
                        <div class="bonus-line-wrapper">
                          <div class="bonus-text" (click)="toggleFolded($event)">
                            Bonusoptjenende timer
                            <mn-info-icon title="Bonusoptjenende timer">
                              Potentielt bonusgivende timer, som er registreret.
                            </mn-info-icon>
                            <button class="unfoldable-icon">Fold ind</button>
                          </div>
                          <div class="bonus-foldable">
                            <div class="bonus-line-wrapper">
                              <div class="bonus-foldable">
                              </div>
                              <div class="bonus-line">
                                <ng-container *ngIf="!bonus.bonusoptjenendeOpgaver?.length">
                                  <i>Ingen</i>
                                </ng-container>
                                <div class="table-responsive" *ngIf="bonus.bonusoptjenendeOpgaver?.length > 0">
                                  <table class="table table-sm">
                                    <thead>
                                      <tr>
                                        <th>Projekt</th>
                                        <th>Opgave</th>
                                        <th class="text-right">Timer</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let opgave of bonus.bonusoptjenendeOpgaver">
                                        <td>{{opgave.projekt}}</td>
                                        <td>{{opgave.opgave}}</td>
                                        <td class="text-right">{{opgave.timer | number:'1.2-2'}}</td>
                                      </tr>
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Samlet</th>
                                        <th></th>
                                        <th class="text-right text-nowrap">
                                          {{getBonusoptjenendeTimer() | number:'1.2-2'}}
                                        </th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="bonus-line" (click)="toggleFolded($event)">
                            <div class="bonus-text">
                              Bonusoptjenende timer
                              <mn-info-icon title="Bonusoptjenende timer">
                                Potentielt bonusgivende timer, som er registreret.
                              </mn-info-icon>
                              <button class="unfoldable-icon">Fold ud</button>
                            </div>
                            <div class="bonus-amount-wrapper">
                              <div class="bonus-amount">
                                {{getBonusoptjenendeTimer() | number:'1.2-2'}}
                              </div>
                              <div class="bonus-unit">
                                timer
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="bonus-line-wrapper">
                          <div class="bonus-text" (click)="toggleFolded($event)">
                            Bonusgrænse
                            <mn-info-icon title="Bonusgrænse">
                              Bonusgrænse for perioden. Dvs. reguleret for evt. registrerede timer, som regulerer bonustimegrænsen.
                            </mn-info-icon>
                            <button class="unfoldable-icon">Fold ind</button>
                          </div>
                          <div class="bonus-foldable">
                            <div class="bonus-line-wrapper">
                              <div class="bonus-text">Bonusgrænse fra kontrakt</div>
                              <div class="bonus-foldable">
                              </div>
                              <div class="bonus-line">
                                <div class="bonus-text">Bonusgrænse fra kontrakt</div>
                                <div class="bonus-amount-wrapper">
                                  <div class="bonus-amount">
                                    {{bonus.kontrakt.bonusgraense | number:'1.2-2'}}
                                  </div>
                                  <div class="bonus-unit">
                                    timer
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="bonus-line-wrapper" *ngIf="getAdhocBonusgraensejustering()">
                              <div class="bonus-text" (click)="toggleFolded($event)">
                                Ad-hoc-bonusgrænsejustering
                                <mn-info-icon title="Ad-hoc-bonusgrænsejustering">
                                  Midlertidig justering af bonusgrænse, som aftales.
                                </mn-info-icon>
                                <button class="unfoldable-icon">Fold ind</button>
                              </div>
                              <div class="bonus-foldable">
                                <ng-container *ngIf="!bonus.adhocbonusgraensejusteringer?.length">
                                  <div class="bonus-line-wrapper">
                                    <div class="bonus-line">
                                      <i>Ingen</i>
                                    </div>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="bonus.adhocbonusgraensejusteringer?.length > 0">
                                  <div class="bonus-line-wrapper"
                                       *ngFor="let bonustimegraensejustering of bonus.adhocbonusgraensejusteringer">
                                    <div class="bonus-line">
                                      <div class="bonus-text">{{bonustimegraensejustering.kommentar}}</div>
                                      <div class="bonus-amount-wrapper">
                                        <div class="bonus-amount">
                                          {{bonustimegraensejustering.timer | number:'1.2-2'}}
                                        </div>
                                        <div class="bonus-unit">
                                          timer
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <div class="bonus-line" (click)="toggleFolded($event)">
                                <div class="bonus-text">
                                  Ad-hoc-bonusgrænsejustering
                                  <mn-info-icon title="Ad-hoc-bonusgrænsejustering">
                                    Midlertidig justering af bonusgrænse, som aftales.
                                  </mn-info-icon>
                                  <button class="unfoldable-icon">Fold ud</button>
                                </div>
                                <div class="bonus-amount-wrapper">
                                  <div class="bonus-operator">
                                    +
                                  </div>
                                  <div class="bonus-amount">
                                    {{getAdhocBonusgraensejustering() | number:'1.2-2'}}
                                  </div>
                                  <div class="bonus-unit">
                                    timer
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="bonus-line-wrapper">
                              <div class="bonus-text" (click)="toggleFolded($event)">
                                Bonusgrænsesænkende timer
                                <mn-info-icon title="Bonusgrænsesænkende timer">
                                  Timer, som sænker din bonustimegrænse.
                                </mn-info-icon>
                                <button class="unfoldable-icon">Fold ind</button>
                              </div>
                              <div class="bonus-foldable">
                                <div class="bonus-line-wrapper">
                                  <div class="bonus-text" (click)="toggleFolded($event)">
                                    Registrede bonusgrænsesænkende timer
                                    <button class="unfoldable-icon">Fold ind</button>
                                  </div>
                                  <div class="bonus-foldable">
                                    <div class="bonus-line-wrapper">
                                      <div class="bonus-foldable">
                                      </div>
                                      <div class="bonus-line">
                                        <ng-container *ngIf="!bonus.bonusgraensenedsaettendeOpgaver?.length">
                                          <i>Ingen</i>
                                        </ng-container>
                                        <div class="table-responsive" *ngIf="bonus.bonusgraensenedsaettendeOpgaver?.length > 0">
                                          <table class="table table-sm">
                                            <thead>
                                              <tr>
                                                <th>Projekt</th>
                                                <th>Opgave</th>
                                                <th class="text-right">Timer</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let opgave of bonus.bonusgraensenedsaettendeOpgaver">
                                                <td>{{opgave.projekt}}</td>
                                                <td>{{opgave.opgave}}</td>
                                                <td class="text-right">{{opgave.timer | number:'1.2-2'}}</td>
                                              </tr>
                                            </tbody>
                                            <tfoot>
                                              <tr>
                                                <th>Samlet</th>
                                                <th></th>
                                                <th class="text-right text-nowrap">
                                                  {{getBonustimegraensenedsaettende() | number:'1.2-2'}}
                                                </th>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="bonus-line" (click)="toggleFolded($event)">
                                    <div class="bonus-text">
                                      Registrede bonusgrænsesænkende timer
                                      <button class="unfoldable-icon">Fold ud</button>
                                    </div>
                                    <div class="bonus-amount-wrapper">
                                      <div class="bonus-amount">
                                        {{getBonustimegraensenedsaettende() | number:'1.2-2'}}
                                      </div>
                                      <div class="bonus-unit">
                                        timer
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="bonus-line-wrapper" *ngIf="bonus.kontrakt.bonusgraensenedsaettelsesmax">
                                  <div class="bonus-foldable">
                                  </div>
                                  <div class="bonus-line">
                                    <div class="bonus-text">
                                      Maks bonusgrænsesænkelse
                                      <mn-info-icon title="Maks bonusgrænsesænkelse">
                                        Hvor mange bonustimegrænsesænkende timer du maksimalt kan have pr periode. Hvis "Ingen" er der ikke angivet en maksværdi.
                                      </mn-info-icon>
                                    </div>
                                    <div class="bonus-amount-wrapper">
                                      <ng-container *ngIf="!bonus.kontrakt.bonusgraensenedsaettelsesmax">
                                        <i>Ingen</i>
                                      </ng-container>
                                      <ng-container *ngIf="bonus.kontrakt.bonusgraensenedsaettelsesmax">
                                        <div class="bonus-operator">
                                          Max
                                        </div>
                                        <div class="bonus-amount">
                                            {{bonus.kontrakt.bonusgraensenedsaettelsesmax | number:'1.2-2'}}
                                        </div>
                                        <div class="bonus-unit">
                                          timer
                                        </div>
                                      </ng-container>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="bonus-line" (click)="toggleFolded($event)">
                                <div class="bonus-text">
                                  Bonusgrænsesænkende timer
                                  <mn-info-icon title="Bonusgrænsesænkende timer">
                                    Timer, som sænker din bonustimegrænse.
                                  </mn-info-icon>
                                  <button class="unfoldable-icon">Fold ud</button>
                                </div>
                                <div class="bonus-amount-wrapper">
                                  <div class="bonus-operator">
                                    -
                                  </div>
                                  <div class="bonus-amount">
                                    <mn-difference-icon mimerValue="{{bonus.bonusberegning.bonusgraensenedsaettendetimer}}" mitNineValue="{{getBonustimegraensenedsaettendeForMaaned()}}"></mn-difference-icon>
                                    {{ bonus.bonusberegning.bonusgraensenedsaettendetimer | number:'1.2-2'}}
                                  </div>
                                  <div class="bonus-unit">
                                    timer
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="bonus-line" (click)="toggleFolded($event)">
                            <div class="bonus-text">
                              Bonusgrænse
                              <mn-info-icon title="Bonusgrænse">
                                Bonusgrænse for perioden. Dvs. reguleret for evt. registrerede timer, som regulerer bonustimegrænsen.
                              </mn-info-icon>
                              <button class="unfoldable-icon">Fold ud</button>
                            </div>
                            <div class="bonus-amount-wrapper">
                              <div class="bonus-operator">
                                -
                              </div>
                              <div class="bonus-amount">
                                {{getBonustimegraenseForMaaned() | number:'1.2-2'}}
                              </div>
                              <div class="bonus-unit">
                                timer
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bonus-line" (click)="toggleFolded($event)">
                        <div class="bonus-text">
                          Udbetalte timer
                          <mn-info-icon title="Udbetalte timer">
                            Det antal timer, som bruges til at udregne bonusudbetalingen.
                          </mn-info-icon>
                          <button class="unfoldable-icon">Fold ud</button>
                        </div>
                        <div class="bonus-amount-wrapper">
                          <div class="bonus-amount">
                            {{getBonusudbetalendeTimer() | number:'1.2-2'}}
                          </div>
                          <div class="bonus-unit">
                            timer
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bonus-line-wrapper">
                      <div class="bonus-text" (click)="toggleFolded($event)">
                        Gennemsnitlig timepris
                        <mn-info-icon title="Gennemsnitlig timepris">
                          Gennemsnitlig bonustimepris for perioden, som bruges til udregning af bonus såfremt du arbejder på opgaver til forskellig timepris
                        </mn-info-icon>
                        <button class="unfoldable-icon">Fold ind</button>
                      </div>
                      <div class="bonus-foldable">
                        <div class="bonus-line-wrapper">
                          <div class="bonus-foldable">
                          </div>
                          <div class="bonus-line">
                            <ng-container *ngIf="!bonus.bonusoptjenendeOpgaver?.length">
                              <i>Ingen</i>
                            </ng-container>
                            <div class="table-responsive" *ngIf="bonus.bonusoptjenendeOpgaver?.length > 0">
                              <table class="table table-sm">
                                <thead>
                                  <tr>
                                    <th>Projekt</th>
                                    <th>Opgave</th>
                                    <th class="text-right">Timer</th>
                                    <th class="text-right">Totalpris</th>
                                    <th class="text-right">Bonustimepris</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let opgave of bonus.bonusoptjenendeOpgaver">
                                    <td>{{opgave.projekt}}</td>
                                    <td>{{opgave.opgave}}</td>
                                    <td class="text-right">{{opgave.timer | number:'1.2-2'}}</td>
                                    <td class="text-right">{{opgave.totalpris | number:'1.2-2'}}</td>
                                    <td class="text-right">{{(opgave.timepris || opgave.totalpris/opgave.timer) |
                                      number:'1.2-2'}}
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th>Samlet</th>
                                    <th></th>
                                    <th class="text-right text-nowrap">
                                      {{getBonusoptjenendeTimer() | number:'1.2-2'}}
                                    </th>
                                    <th class="text-right text-nowrap">
                                      {{getTotalBonusoptjening() | number:'1.2-2'}}
                                    </th>
                                    <th class="text-right text-nowrap">
                                      {{getGennemsnitligBonustimepris() | number:'1.2-2'}}
                                    </th>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bonus-line" (click)="toggleFolded($event)">
                        <div class="bonus-text">
                          Gennemsnitlig timepris
                          <mn-info-icon title="Gennemsnitlig timepris">
                            Gennemsnitlig bonustimepris for perioden, som bruges til udregning af bonus såfremt du arbejder på opgaver til forskellig timepris
                          </mn-info-icon>
                          <button class="unfoldable-icon">Fold ud</button>
                        </div>
                        <div class="bonus-amount-wrapper">
                          <div class="bonus-operator">
                            x
                          </div>
                          <div class="bonus-amount">
                            {{getGennemsnitligBonustimepris() | number:'1.2-2'}}
                          </div>
                          <div class="bonus-unit">
                            kr.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bonus-line-wrapper">
                      <div class="bonus-foldable">
                      </div>
                      <div class="bonus-line">
                        <div class="bonus-text">Bonusprocent fra kontrakt</div>
                        <div class="bonus-amount-wrapper">
                          <div class="bonus-operator">
                            x
                          </div>
                          <div class="bonus-amount">
                            {{bonus.kontrakt.bonusprocent | number:'1.2-2'}}
                          </div>
                          <div class="bonus-unit">
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bonus-line" (click)="toggleFolded($event)">
                    <div class="bonus-text">
                      Timebonus
                      <mn-info-icon title="Timebonus">
                        Bonusbeløb, baseret på timer
                      </mn-info-icon>
                      <button class="unfoldable-icon">Fold ud</button>
                    </div>
                    <div class="bonus-amount-wrapper">
                      <div class="bonus-amount">
                        <mn-difference-icon mimerValue="{{bonus.bonusberegning.timebonusbeloeb}}" mitNineValue="{{getUdbetaltTimebonus()}}"></mn-difference-icon>
                        {{bonus.bonusberegning.timebonusbeloeb | number:'1.2-2'}}
                      </div>
                      <div class="bonus-unit">
                        kr.
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Ad-hoc-bonus -->
                <div class="bonus-line-wrapper">
                  <div class="bonus-text" (click)="toggleFolded($event)">
                    Ad-hoc-bonus
                    <mn-info-icon title="Ad-hoc-bonus">
                      Ekstraordinær bonus hvis der findes en sådan for perioden.
                    </mn-info-icon>
                    <button class="unfoldable-icon">Fold ind</button>
                  </div>
                  <div class="bonus-foldable">
                    <ng-container *ngIf="!bonus.adhocbonuser?.length">
                      <div class="bonus-line-wrapper">
                        <div class="bonus-line">
                          <i>Ingen</i>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="bonus.adhocbonuser?.length > 0">
                    <div class="bonus-line-wrapper" *ngFor="let bonus of bonus.adhocbonuser">
                        <div class="bonus-line">
                          <div class="bonus-text">{{bonus.kommentar}}</div>
                          <div class="bonus-amount-wrapper">
                            <div class="bonus-amount">
                              {{bonus.beloeb | number:'1.2-2'}}
                            </div>
                            <div class="bonus-unit">
                              kr.
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="bonus-line" (click)="toggleFolded($event)">
                    <div class="bonus-text">
                      Ad-hoc-bonus
                      <mn-info-icon title="Ad-hoc-bonus">
                        Ekstraordinær bonus hvis der findes en sådan for perioden.
                      </mn-info-icon>
                      <button class="unfoldable-icon">Fold ud</button>
                    </div>
                    <div class="bonus-amount-wrapper">
                      <div class="bonus-operator">
                        +
                      </div>
                      <div class="bonus-amount">
                        <mn-difference-icon mimerValue="{{bonus.bonusberegning.adhocBonusbeloeb}}" mitNineValue="{{getUdbetaltAdhocbonus()}}"></mn-difference-icon>
                        {{bonus.bonusberegning.adhocBonusbeloeb | number:'1.2-2'}}
                      </div>
                      <div class="bonus-unit">
                        kr.
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <!-- Total bonus -->
              <div class="bonus-line" (click)="toggleFolded($event)">
                <div class="bonus-text">
                  Udbetalt bonus
                  <mn-info-icon title="Udbetalt bonus">
                    Totalbonus, som er udbetalt sammen med lønnen.
                  </mn-info-icon>
                  <button class="unfoldable-icon">Fold ud</button>
                </div>
                <div class="bonus-amount-wrapper">
                  <div class="bonus-amount">
                    <mn-difference-icon mimerValue="{{bonus.bonusberegning.samletBonusbeloeb}}" mitNineValue="{{getUdbetaltBonus()}}"></mn-difference-icon>
                    {{bonus.bonusberegning.samletBonusbeloeb | number:'1.2-2'}}
                  </div>
                  <div class="bonus-unit">
                    kr.
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

    <div class="card-deck m-3">

      <div class="card bg-light">
        <div class="card-header">
          Opgaveregistreringer
          <mn-info-icon title="Opgaveregistreringer">
            Fordeling af registrerede timer
          </mn-info-icon>
        </div>
        <div class="card-body">

          <div class="card-deck mb-3">

            <div class="card">
              <div class="card-header">
                Bonusoptjenende opgaver
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Projekt</th>
                        <th>Opgave</th>
                        <th class="text-right">Timer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let opgave of bonus.bonusoptjenendeOpgaver">
                        <td>{{opgave.projekt}}</td>
                        <td>{{opgave.opgave}}</td>
                        <td class="text-right text-nowrap">{{opgave.timer | number:'1.2-2'}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Samlet</th>
                        <th></th>
                        <th class="text-right text-nowrap">
                          {{getBonusoptjenendeTimer() | number:'1.2-2'}}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

          </div>

          <div class="card-deck mb-3">

            <div class="card">
              <div class="card-header">
                Bonusgrænsenedsættende opgaver
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Projekt</th>
                        <th>Opgave</th>
                        <th class="text-right">Timer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let opgave of bonus.bonusgraensenedsaettendeOpgaver">
                        <td>{{opgave.projekt}}</td>
                        <td>{{opgave.opgave}}</td>
                        <td class="text-right text-nowrap">{{opgave.timer | number:'1.2-2'}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Samlet</th>
                        <th></th>
                        <th class="text-right text-nowrap">
                          {{getBonustimegraensenedsaettende() | number:'1.2-2'}}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

          </div>

          <div class="card-deck">

            <div class="card">
              <div class="card-header">
                Andre opgaver
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Projekt</th>
                        <th>Opgave</th>
                        <th class="text-right">Timer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let opgave of bonus.andreOpgaver">
                        <td>{{opgave.projekt}}</td>
                        <td>{{opgave.opgave}}</td>
                        <td class="text-right text-nowrap">{{opgave.timer | number:'1.2-2'}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Samlet</th>
                        <th></th>
                        <th class="text-right text-nowrap">
                          {{getAndreTimer() | number:'1.2-2'}}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>

</div>
