<ng-container *ngIf="!isTolerant()">

  <ng-template #popContent>
    <table>
      <tr>
        <td>Mimer</td>
        <td>{{mimerValue | number:'1.2-2'}} kr</td>
      </tr>
      <tr>
        <td>Mit Nine</td>
        <td>{{mitNineValue | number:'1.2-2'}} kr</td>
      </tr>
      <tr>
        <td>Forskel</td>
        <td>{{difference() | number:'1.2-2'}} kr</td>
      </tr>
    </table>
  </ng-template>

  <button type="button" class="difference-icon" [ngbPopover]="popContent" popoverTitle="{{title}}"
          triggers="mouseenter:mouseleave">
    {{title}}
  </button>

</ng-container>
