<header>
    <!-- Fixed navbar -->
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <a class="navbar-brand" href="#">Mit Nine</a>
        <button class="navbar-toggler" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" [attr.aria-expanded]="!isNavbarCollapsed"
            aria-controls="navbarCollapse" data-toggle="collapse" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngbCollapse]="isNavbarCollapsed">
            <ul class="navbar-nav mr-auto">
              <ng-container *ngIf="user$ | async">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link nav-link-grundloen" routerLink="/grundloen">Grundløn</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link nav-link-pension" routerLink="/pension">Pension</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link nav-link-bonus" routerLink="/bonus">Bonus</a>
                </li>
              </ng-container>
            </ul>
          <div class="authorization-panel">
            <ng-container *ngIf="user$ | async as user; else showLogin">
              <div class="authorization-name navbar-text mr-2">{{user.name}}</div>
            </ng-container>
            <ng-template #showLogin>
              <button (click)="login()" class="authorization-login btn btn-sm btn-dark btn-outline-light">Log in</button>
            </ng-template>
          </div>
        </div>
    </nav>
</header>

<!-- Begin page content -->
<main role="main" class="container-fluid">

  <router-outlet></router-outlet>

</main>
