import { Component, OnInit, Input } from '@angular/core';
import { GrundloenService } from '../grundloen.service';

@Component({
  selector: 'mn-grundloen-view',
  templateUrl: './grundloen-view.component.html',
  styleUrls: ['./grundloen-view.component.scss']
})
export class GrundloenViewComponent implements OnInit {

  grundloen: any;
  error: string;
  loading: boolean = false;
  latestAar: number;
  latestMaaned: number;
  aar: number;
  maaned: number;

  constructor(private grundloenService: GrundloenService) {
  }

  ngOnInit() {
    this.error = "";
    this.loading = true;
    this.grundloenService.getLatestGrundloen().subscribe(
      data => {
        console.info("Success loading data", data);
        this.grundloen = data;
        this.latestAar = this.grundloen.loenperiode.aar;
        this.latestMaaned = this.grundloen.loenperiode.maaned;
        this.aar = this.latestAar;
        this.maaned = this.latestMaaned;
        this.loading = false;
      },
      err => {
        console.error("Error loading data", err);
        if (err.status === 404) {
          this.error = "Ingen data fundet";
        } else {
          this.error = "Failed";
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
        console.log('Done loading grundloen', this.grundloen);
      }
    );
  }

  getMaanedList() {
    if (this.aar === this.latestAar) {
      return Array.from(new Array(this.latestMaaned), (val, index) => index+1);
    }
    return Array.from(new Array(12), (val, index) => index+1);
  }

  getArrList() {
    return Array.from(new Array((this.latestAar || new Date().getFullYear()) - 2008 + 1), (val, index) => 2008 + index);
  }

  hentData() {
    this.error = "";
    this.loading = true;
    this.grundloenService.getGrundloen(this.aar, this.maaned).subscribe(
      data => {
        console.info("Success loading data", data);
        this.grundloen = data;
        this.loading = false;
      },
      err => {
        console.error("Error loading grundloen", err, err.status, err.status === 404);
        if (err.status === 404) {
          this.error = "Ingen data fundet";
        } else {
          this.error = "Failed";
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
        console.log('Done loading grundloen', this.grundloen);
      }
    );
  }

  toggleFolded(event) {
    event.currentTarget.parentElement.classList.toggle("bonus-unfolded");
    event.currentTarget.parentElement.querySelectorAll(".bonus-unfolded").forEach(e => e.classList.remove("bonus-unfolded"));
  }

  getUbetaltGrundloen() {
    return this.getBeloebInclPension() - this.getNinePensionBeloeb();
  }

  getBeloebInclPension() {
    return this.grundloen.kontrakt.basisloen + this.getAdhocbeloeb();
  }

  getAdhocbeloeb() {
    return this.grundloen.adhocLoen.map(loen => loen.beloeb).reduce((acc, cur) => {
      return acc + cur;
    }, 0);
  }

  getNinePensionBeloeb() {
    return this.getBeloebInclPension() * this.grundloen.kontrakt.ninePensionProcent / 100;
  }

}
