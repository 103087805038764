import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthName'
})
export class MonthNamePipe implements PipeTransform {

  private maaneder = [
    {nummer: 1, navn: "Januar"},
    {nummer: 2, navn: "Februar"},
    {nummer: 3, navn: "Marts"},
    {nummer: 4, navn: "April"},
    {nummer: 5, navn: "Maj"},
    {nummer: 6, navn: "Juni"},
    {nummer: 7, navn: "Juli"},
    {nummer: 8, navn: "August"},
    {nummer: 9, navn: "September"},
    {nummer: 10, navn: "Oktober"},
    {nummer: 11, navn: "November"},
    {nummer: 12, navn: "December"}
  ];

  transform(nummer: number): string {
    return this.maaneder.find(value => value.nummer === nummer).navn;
  }

}
