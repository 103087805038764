import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mn-difference-icon',
  templateUrl: './difference-icon.component.html',
  styleUrls: ['./difference-icon.component.scss']
})
export class DifferenceIconComponent implements OnInit {

  @Input()
  mimerValue : number;

  @Input()
  mitNineValue : number;

  @Input()
  tolerance : number = 1;

  @Input()
  title : string = "Forskel i beregning";

  constructor() { }

  ngOnInit() {
  }

  isTolerant() {
    return Math.abs(this.difference()) < this.tolerance;
  }

  difference() {
    return this.mimerValue - this.mitNineValue;
  }

}
