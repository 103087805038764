<h1>Grundløn</h1>

<div>

  <div class="card-deck m-3">

    <div class="card bg-light">
      <div class="card-header">
        Vælg data
      </div>
      <div class="card-body">

        <div *ngIf="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>

        <form onsubmit="return false">
          <fieldset [disabled]="loading">
            <div class="form-group">
              <label for="aar">
                Lønperiode
                <mn-info-icon title="Lønperiode">
                  Perioden hvor den udbetalte løn er optjent.
                </mn-info-icon>
              </label>
              <div class="input-group">
                <select name="aar" id="aar" class="form-control" title="År" [(ngModel)]="aar">
                  <option *ngFor="let x of getArrList()">{{x}}</option>
                </select>
                <select name="maaned" class="form-control" title="Måned" [(ngModel)]="maaned">
                  <option *ngFor="let x of getMaanedList()" value="{{x}}">{{x | monthName}}</option>
                </select>
              </div>
            </div>
            <button (click)="hentData()" class="btn btn-primary">Hent data</button>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>

<div>

  <div *ngIf="loading" style="text-align: center;">
    <i class="fa fa-spinner fa-spin" style="font-size:24px"></i> Indlæser data...
  </div>

  <div *ngIf="grundloen && !loading && !error">

    <div class="card-deck m-3">

      <div class="card bg-light">
        <div class="card-header">
          Grundlønberegning
          <mn-info-icon title="Grundlønberegning">
            Her ses hvorledes grundløn for perioden er udregnet. Klik på linjer for yderligere detaljer
          </mn-info-icon>
        </div>

        <div class="card-body">

          <div class="bonus-div">

            <div class="bonus-line-wrapper bonus-unfolded">
              <div class="bonus-text" (click)="toggleFolded($event)">
                Udbetalt grundløn
                <mn-info-icon title="Udbetalt grundløn">
                  Total udbetalt grundløn, som er udbetalt sammen med lønnen.
                </mn-info-icon>
                <button class="unfoldable-icon">Fold ind</button>
              </div>
              <div class="bonus-foldable">

                <div class="bonus-line-wrapper">
                  <div class="bonus-text" (click)="toggleFolded($event)">
                    Grundløn inkl. pension
                    <mn-info-icon title="Grundløn inkl. pension">
                      Basisløn og adhoc-løn som der betales pension af
                    </mn-info-icon>
                    <button class="unfoldable-icon">Fold ind</button>
                  </div>
                  <div class="bonus-foldable">
                    <div class="bonus-line-wrapper">
                      <div class="bonus-foldable">
                      </div>
                      <div class="bonus-line">
                        <div class="bonus-text">
                          Basisløn fra kontrakt
                        </div>
                        <div class="bonus-amount-wrapper">
                          <div class="bonus-amount">
                            {{grundloen.kontrakt.basisloen | number:'1.2-2'}}
                          </div>
                          <div class="bonus-unit">
                            kr.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="bonus-line-wrapper">
                      <div class="bonus-text" (click)="toggleFolded($event)">
                        Adhoc-løn
                        <mn-info-icon title="Adhoc-løn">
                          Ekstraordinær løn hvis der findes en sådan for perioden. Kunne fx være manglende udbetaling pga. lønforhøjelse med bagudrettet virkning.
                        </mn-info-icon>
                        <button class="unfoldable-icon">Fold ind</button>
                      </div>
                      <div class="bonus-foldable">
                        <ng-container *ngIf="!grundloen.adhocLoen?.length">
                          <div class="bonus-line-wrapper">
                            <div class="bonus-line">
                              <i>Ingen</i>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="grundloen.adhocLoen?.length > 0">
                          <div class="bonus-line-wrapper" *ngFor="let loen of grundloen.adhocLoen">
                            <div class="bonus-line">
                              <div class="bonus-text">{{loen.kommentar}}</div>
                              <div class="bonus-amount-wrapper">
                                <div class="bonus-amount">
                                  {{loen.beloeb | number:'1.2-2'}}
                                </div>
                                <div class="bonus-unit">
                                  kr.
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div class="bonus-line" (click)="toggleFolded($event)">
                        <div class="bonus-text">
                          Adhoc-løn
                          <mn-info-icon title="Adhoc-løn">
                            Ekstraordinær løn hvis der findes en sådan for perioden. Kunne fx være manglende udbetaling pga. lønforhøjelse med bagudrettet virkning.
                          </mn-info-icon>
                          <button class="unfoldable-icon">Fold ud</button>
                        </div>
                        <div class="bonus-amount-wrapper">
                          <div class="bonus-operator">
                            +
                          </div>
                          <div class="bonus-amount">
                            {{getAdhocbeloeb() | number:'1.2-2'}}
                          </div>
                          <div class="bonus-unit">
                            kr.
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="bonus-line" (click)="toggleFolded($event)">
                    <div class="bonus-text">
                      Grundløn inkl. pension
                      <mn-info-icon title="Grundløn inkl. pension">
                        Basisløn og adhoc-løn som der betales pension af
                      </mn-info-icon>
                      <button class="unfoldable-icon">Fold ud</button>
                    </div>
                    <div class="bonus-amount-wrapper">
                      <div class="bonus-amount">
                        <mn-difference-icon mimerValue="{{grundloen.loenberegning.beloebInclPension}}" mitNineValue="{{getBeloebInclPension()}}"></mn-difference-icon>
                        {{grundloen.loenberegning.beloebInclPension | number:'1.2-2'}}
                      </div>
                      <div class="bonus-unit">
                        kr.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bonus-line-wrapper">
                  <div class="bonus-text" (click)="toggleFolded($event)">
                    Nine pension
                    <mn-info-icon title="Grundløn inkl. pension">
                      Pension trækkes fra grundløn, da det ikke regnes med som grundløn på lønseddel, men har sin egen linje.
                    </mn-info-icon>
                    <button class="unfoldable-icon">Fold ind</button>
                  </div>
                  <div class="bonus-foldable">

                    <div class="bonus-line-wrapper">
                      <div class="bonus-foldable">
                      </div>
                      <div class="bonus-line">
                        <div class="bonus-text">
                          Pensionsgrundlag
                          <mn-info-icon title="Pensionsgrundlag">
                            Samlet grundløn inkl. pension (Basisløn + adhoc-løn) som der betales pension af
                          </mn-info-icon>
                        </div>
                        <div class="bonus-amount-wrapper">
                          <div class="bonus-amount">
                            {{getBeloebInclPension() | number:'1.2-2'}}
                          </div>
                          <div class="bonus-unit">
                            kr.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="bonus-line-wrapper">
                      <div class="bonus-foldable">
                      </div>
                      <div class="bonus-line">
                        <div class="bonus-text">
                          Nine pensionprocent
                        </div>
                        <div class="bonus-amount-wrapper">
                          <div class="bonus-amount">
                            {{grundloen.kontrakt.ninePensionProcent | number:'1.2-2'}}
                          </div>
                          <div class="bonus-unit">
                            %
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="bonus-line" (click)="toggleFolded($event)">
                    <div class="bonus-text">
                      Nine pension
                      <mn-info-icon title="Grundløn inkl. pension">
                        Pension trækkes fra grundløn, da det ikke regnes med som grundløn på lønseddel, men har sin egen linje.
                      </mn-info-icon>
                      <button class="unfoldable-icon">Fold ud</button>
                    </div>
                    <div class="bonus-amount-wrapper">
                      <div class="bonus-operator">
                        -
                      </div>
                      <div class="bonus-amount">
                        {{getNinePensionBeloeb() | number:'1.2-2'}}
                      </div>
                      <div class="bonus-unit">
                        kr.
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <!-- Udbetalt grundloen -->
              <div class="bonus-line" (click)="toggleFolded($event)">
                <div class="bonus-text">
                  Udbetalt grundløn
                  <mn-info-icon title="Udbetalt grundløn">
                    Total udbetalt grundløn, som er udbetalt sammen med lønnen.
                  </mn-info-icon>
                  <button class="unfoldable-icon">Fold ud</button>
                </div>
                <div class="bonus-amount-wrapper">
                  <div class="bonus-amount">
                    {{getUbetaltGrundloen() | number:'1.2-2'}}
                  </div>
                  <div class="bonus-unit">
                    kr.
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

</div>
