import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from "rxjs";

import { AuthService, GoogleLoginProvider, SocialUser } from 'angular5-social-login';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isNavbarCollapsed = true;

  user$: Observable<SocialUser>;

  constructor(private router: Router, private socialAuthService: AuthService) {
  }

  ngOnInit() {
    this.user$ = this.socialAuthService.authState;
  }

  login() {
    console.log("Login");
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(userData => {
      console.log("Login success", userData);
    }).catch(reason => {
      console.log("Login failed", reason);
      this.router.navigate(['/']);
    });
  }

  logout() {
    console.log("Logout");
    this.socialAuthService.signOut().then(value => {
      console.log("Logout success");
      this.router.navigate(['/']);
    }).catch(reason => {
      console.log("Logout failed", reason);
      this.router.navigate(['/']);
    });
  }

}
