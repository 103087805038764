import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as rxOps from 'rxjs/operators';
import { AuthService } from 'angular5-social-login';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PensionService {

  constructor(private http: HttpClient, private socialAuthService: AuthService) {
  }

  getLatestPension(): Observable<any> {
    return this.getPension("", "")
  }

  getPension(aar, maaned): Observable<any> {
    return this.socialAuthService.authState.pipe(rxOps.switchMap(user => {
      console.log("token", user.idToken);
      return this.http.get(environment.aws.lambda.getPension.url, {
        params: {
          "aar": aar,
          "maaned": maaned
        },
        headers: new HttpHeaders().set("Authorization", "Bearer " + user.idToken)
      });
    }));
    /*
    return rx.of({
      loenperiode: {
        aar: 2018,
        maaned: 9
      },
      medarbejder: {
        navn: "Jens Jensen",
        medarbejdernummer: 123,
        initialer: "JJE"
      },
      kontrakt: {
        ninePensionProcent: 4.76,
        egenPensionProcent: 5.0
      },
      pensionberegning: {
        samletGrundloenInclPension: 50000,
        ninePensionBeloeb: null,
        egenPensionBeloeb: null,
        egenAdhocPensionBeloeb: null,
        egenSamletPensionBeloeb: null,
        samletPensionBeloeb: null,
      },
      adhocPension: [
        {
          kommentar: "Ekstra indbetaling",
          beloeb: 5000
        }
      ]
    });
    */
  }

}
