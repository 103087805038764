export const environment = {
  production: true,
  aws: {
    lambda: {
      getBonus: {
        url: "https://djr15gjm3c.execute-api.us-east-1.amazonaws.com/mit-nine-dev/get-bonus"
      },
      getGrundloen: {
        url: "https://djr15gjm3c.execute-api.us-east-1.amazonaws.com/mit-nine-dev/get-grundloen"
      },
      getPension: {
        url: "https://djr15gjm3c.execute-api.us-east-1.amazonaws.com/mit-nine-dev/get-pension"
      }
    }
  }
};
