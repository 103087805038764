<h1>Grundløn</h1>

<div>

  <div class="card-deck m-3">

    <div class="card bg-light">
      <div class="card-header">
        Vælg data
      </div>
      <div class="card-body">

        <div *ngIf="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>

        <form onsubmit="return false">
          <fieldset [disabled]="loading">
            <div class="form-group">
              <label for="aar">
                Lønperiode
                <mn-info-icon title="Lønperiode">
                  Perioden hvor den udbetalte løn er optjent.
                </mn-info-icon>
              </label>
              <div class="input-group">
                <select name="aar" id="aar" class="form-control" title="År" [(ngModel)]="aar">
                  <option *ngFor="let x of getArrList()">{{x}}</option>
                </select>
                <select name="maaned" class="form-control" title="Måned" [(ngModel)]="maaned">
                  <option *ngFor="let x of getMaanedList()" value="{{x}}">{{x | monthName}}</option>
                </select>
              </div>
            </div>
            <button (click)="hentData()" class="btn btn-primary">Hent data</button>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>

<div>

  <div *ngIf="loading" style="text-align: center;">
    <i class="fa fa-spinner fa-spin" style="font-size:24px"></i> Indlæser data...
  </div>

  <div *ngIf="pension && !loading && !error">

    <div class="card-deck m-3">

      <div class="card bg-light">
        <div class="card-header">
          Pensionberegning
          <mn-info-icon title="Pensionberegning">
            Her ses hvorledes pension for perioden er udregnet. Klik på linjer for yderligere detaljer
          </mn-info-icon>
        </div>

        <div class="card-body">

          <div class="bonus-div">

            <div class="bonus-line-wrapper bonus-unfolded">
              <div class="bonus-text" (click)="toggleFolded($event)">
                Samlet pension
                <mn-info-icon title="Udbetalt grundløn">
                  Total pension (egen og arbejdsgiver), som er indbetalt til pensionsopsparing.
                </mn-info-icon>
                <button class="unfoldable-icon">Fold ind</button>
              </div>
              <div class="bonus-foldable">

                <div class="bonus-line-wrapper">
                  <div class="bonus-text" (click)="toggleFolded($event)">
                    Arbejdsgiverpension
                    <mn-info-icon title="Arbejdsgiverpension">
                      Arbejdsgiverandel af pensionsindbetaling for perioden.
                    </mn-info-icon>
                    <button class="unfoldable-icon">Fold ind</button>
                  </div>
                  <div class="bonus-foldable">

                    <div class="bonus-line-wrapper">
                      <div class="bonus-foldable">
                      </div>
                      <div class="bonus-line">
                        <div class="bonus-text">
                          Pensionsgrundlag
                          <mn-info-icon title="Pensionsgrundlag">
                            Grundlag for arbejgersgiverpension er den fulde grundløn for lønperioden.
                          </mn-info-icon>
                        </div>
                        <div class="bonus-amount-wrapper">
                          <div class="bonus-amount">
                            {{pension.pensionberegning.samletGrundloenInclPension | number:'1.2-2'}}
                          </div>
                          <div class="bonus-unit">
                            kr.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="bonus-line-wrapper">
                      <div class="bonus-foldable">
                      </div>
                      <div class="bonus-line">
                        <div class="bonus-text">
                          Arbejdsgivers pensionprocent
                        </div>
                        <div class="bonus-amount-wrapper">
                          <div class="bonus-operator">
                            x
                          </div>
                          <div class="bonus-amount">
                            {{pension.kontrakt.ninePensionProcent | number:'1.2-2'}}
                          </div>
                          <div class="bonus-unit">
                            %
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="bonus-line" (click)="toggleFolded($event)">
                    <div class="bonus-text">
                      Arbejdsgiverpension
                      <mn-info-icon title="Pensionsgrundlag for arbejdsgiverpension">
                        Arbejdsgiverandel af pensionsindbetaling for perioden.
                      </mn-info-icon>
                      <button class="unfoldable-icon">Fold ud</button>
                    </div>
                    <div class="bonus-amount-wrapper">
                      <div class="bonus-amount">
                        {{getNinePensionBeloeb() | number:'1.2-2'}}
                      </div>
                      <div class="bonus-unit">
                        kr.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bonus-line-wrapper">
                  <div class="bonus-text" (click)="toggleFolded($event)">
                    Egen pension
                    <mn-info-icon title="Arbejdsgiverpension">
                      Egen andel af pensionsindbetaling for perioden.
                    </mn-info-icon>
                    <button class="unfoldable-icon">Fold ind</button>
                  </div>
                  <div class="bonus-foldable">

                    <div class="bonus-line-wrapper">
                      <div class="bonus-text" (click)="toggleFolded($event)">
                        Beregnet pension
                        <mn-info-icon title="Beregnet pension">
                          Egen andel af pensionsindbetaling, beregnet ud fra procent, for perioden.
                        </mn-info-icon>
                        <button class="unfoldable-icon">Fold ind</button>
                      </div>
                      <div class="bonus-foldable">

                        <div class="bonus-line-wrapper">
                          <div class="bonus-foldable">
                          </div>
                          <div class="bonus-line">
                            <div class="bonus-text">
                              Pensionsgrundlag
                              <mn-info-icon title="Pensionsgrundlag">
                                Grundlag for egen pension er den fulde grundløn minus arbejdsgiverpension for
                                lønperioden.
                              </mn-info-icon>
                            </div>
                            <div class="bonus-amount-wrapper">
                              <div class="bonus-amount">
                                {{getEgenPensionGrundlag() | number:'1.2-2'}}
                              </div>
                              <div class="bonus-unit">
                                kr.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="bonus-line-wrapper">
                          <div class="bonus-foldable">
                          </div>
                          <div class="bonus-line">
                            <div class="bonus-text">
                              Egen pensionprocent
                            </div>
                            <div class="bonus-amount-wrapper">
                              <div class="bonus-operator">
                                x
                              </div>
                              <div class="bonus-amount">
                                {{pension.kontrakt.egenPensionProcent | number:'1.2-2'}}
                              </div>
                              <div class="bonus-unit">
                                %
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="bonus-line" (click)="toggleFolded($event)">
                        <div class="bonus-text">
                          Beregnet pension
                          <mn-info-icon title="Beregnet pension">
                            Egen andel af pensionsindbetaling, beregnet ud fra procent, for perioden.
                          </mn-info-icon>
                          <button class="unfoldable-icon">Fold ud</button>
                        </div>
                        <div class="bonus-amount-wrapper">
                          <div class="bonus-amount">
                            {{getEgenPensionBeloeb() | number:'1.2-2'}}
                          </div>
                          <div class="bonus-unit">
                            kr.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="bonus-line-wrapper">
                      <div class="bonus-text" (click)="toggleFolded($event)">
                        Adhoc-pension
                        <mn-info-icon title="Adhoc-pension">
                          Ekstraordinær egen pensionindbetaling hvis der findes en sådan for perioden.
                        </mn-info-icon>
                        <button class="unfoldable-icon">Fold ind</button>
                      </div>
                      <div class="bonus-foldable">
                        <ng-container *ngIf="!pension.adhocPension?.length">
                          <div class="bonus-line-wrapper">
                            <div class="bonus-line">
                              <i>Ingen</i>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="pension.adhocPension?.length > 0">
                          <div class="bonus-line-wrapper" *ngFor="let loen of pension.adhocPension">
                            <div class="bonus-line">
                              <div class="bonus-text">{{loen.kommentar}}</div>
                              <div class="bonus-amount-wrapper">
                                <div class="bonus-amount">
                                  {{loen.beloeb | number:'1.2-2'}}
                                </div>
                                <div class="bonus-unit">
                                  kr.
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div class="bonus-line" (click)="toggleFolded($event)">
                        <div class="bonus-text">
                          Adhoc-pension
                          <mn-info-icon title="Adhoc-pension">
                            Ekstraordinær egen pensionindbetaling hvis der findes en sådan for perioden.
                          </mn-info-icon>
                          <button class="unfoldable-icon">Fold ud</button>
                        </div>
                        <div class="bonus-amount-wrapper">
                          <div class="bonus-operator">
                            +
                          </div>
                          <div class="bonus-amount">
                            {{getAdhocBeloeb() | number:'1.2-2'}}
                          </div>
                          <div class="bonus-unit">
                            kr.
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="bonus-line" (click)="toggleFolded($event)">
                    <div class="bonus-text">
                      Egen pension
                      <mn-info-icon title="Egen pension">
                        Egen andel af pensionsindbetaling for perioden.
                      </mn-info-icon>
                      <button class="unfoldable-icon">Fold ud</button>
                    </div>
                    <div class="bonus-amount-wrapper">
                      <div class="bonus-operator">
                        +
                      </div>
                      <div class="bonus-amount">
                        {{getSamletEgenPensionBeloeb() | number:'1.2-2'}}
                      </div>
                      <div class="bonus-unit">
                        kr.
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <!-- Samlet pension -->
              <div class="bonus-line" (click)="toggleFolded($event)">
                <div class="bonus-text">
                  Samlet pension
                  <mn-info-icon title="Udbetalt grundløn">
                    Total pension (egen og arbejdsgiver), som er indbetalt til pensionsopsparing.
                  </mn-info-icon>
                  <button class="unfoldable-icon">Fold ud</button>
                </div>
                <div class="bonus-amount-wrapper">
                  <div class="bonus-amount">
                    {{getSamletPension() | number:'1.2-2'}}
                  </div>
                  <div class="bonus-unit">
                    kr.
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>
