import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as rxOps from 'rxjs/operators';
import { AuthService } from 'angular5-social-login';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GrundloenService {

  constructor(private http: HttpClient, private socialAuthService: AuthService) {
  }

  getLatestGrundloen(): Observable<any> {
    return this.getGrundloen("", "");
  }

  getGrundloen(aar, maaned): Observable<any> {
    return this.socialAuthService.authState.pipe(rxOps.switchMap(user => {
      console.log("token", user.idToken);
      return this.http.get(environment.aws.lambda.getGrundloen.url, {
        params: {
          "aar": aar,
          "maaned": maaned
        },
        headers: new HttpHeaders().set("Authorization", "Bearer " + user.idToken)
      });
    }));
    /*
    return rx.of({
      loenperiode: {
        aar: 2018,
        maaned: 9
      },
      medarbejder: {
        navn: "JensJensen",
        medarbejdernummer: 123,
        initialer: "JJE"
      },
      kontrakt: {
        basisloen: 40000,
        ninePensionProcent: 4.76
      },
      loenberegning: {
        udbetaltBeloeb: null,
        beloebInclPension: 50000,
        adhocBeloeb: null,
        ninePensionBeloeb: null
      },
      adhocLoen: [
        {
          kommentar: "Lønjustering",
          beloeb: 10000
        }
      ]
    });
    */
  }

}
