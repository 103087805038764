import { AuthServiceConfig, GoogleLoginProvider } from "angular5-social-login";

export function socialLoginConfig() {
  let config = new AuthServiceConfig(
    [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider("1089730287623-gqf08rnc4pcn4b95v2igd9rbtjh48h7j.apps.googleusercontent.com")
      },
    ]
  );
  return config;
}

