import { AuthService } from 'angular5-social-login';
import * as rxOperators from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "angular5-social-login";
var AuthenticatedGuard = /** @class */ (function () {
    function AuthenticatedGuard(socialAuthService) {
        this.socialAuthService = socialAuthService;
    }
    AuthenticatedGuard.prototype.canActivate = function (next, state) {
        return this.socialAuthService.authState.pipe(rxOperators.map(function (x) { return !!x; }));
    };
    AuthenticatedGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticatedGuard_Factory() { return new AuthenticatedGuard(i0.inject(i1.AuthService)); }, token: AuthenticatedGuard, providedIn: "root" });
    return AuthenticatedGuard;
}());
export { AuthenticatedGuard };
