import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as rxOps from 'rxjs/operators';
import { AuthService } from 'angular5-social-login';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular5-social-login";
var PensionService = /** @class */ (function () {
    function PensionService(http, socialAuthService) {
        this.http = http;
        this.socialAuthService = socialAuthService;
    }
    PensionService.prototype.getLatestPension = function () {
        return this.getPension("", "");
    };
    PensionService.prototype.getPension = function (aar, maaned) {
        var _this = this;
        return this.socialAuthService.authState.pipe(rxOps.switchMap(function (user) {
            console.log("token", user.idToken);
            return _this.http.get(environment.aws.lambda.getPension.url, {
                params: {
                    "aar": aar,
                    "maaned": maaned
                },
                headers: new HttpHeaders().set("Authorization", "Bearer " + user.idToken)
            });
        }));
        /*
        return rx.of({
          loenperiode: {
            aar: 2018,
            maaned: 9
          },
          medarbejder: {
            navn: "Jens Jensen",
            medarbejdernummer: 123,
            initialer: "JJE"
          },
          kontrakt: {
            ninePensionProcent: 4.76,
            egenPensionProcent: 5.0
          },
          pensionberegning: {
            samletGrundloenInclPension: 50000,
            ninePensionBeloeb: null,
            egenPensionBeloeb: null,
            egenAdhocPensionBeloeb: null,
            egenSamletPensionBeloeb: null,
            samletPensionBeloeb: null,
          },
          adhocPension: [
            {
              kommentar: "Ekstra indbetaling",
              beloeb: 5000
            }
          ]
        });
        */
    };
    PensionService.ngInjectableDef = i0.defineInjectable({ factory: function PensionService_Factory() { return new PensionService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: PensionService, providedIn: "root" });
    return PensionService;
}());
export { PensionService };
