import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as rxOps from 'rxjs/operators';
import { AuthService } from 'angular5-social-login';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular5-social-login";
var GrundloenService = /** @class */ (function () {
    function GrundloenService(http, socialAuthService) {
        this.http = http;
        this.socialAuthService = socialAuthService;
    }
    GrundloenService.prototype.getLatestGrundloen = function () {
        return this.getGrundloen("", "");
    };
    GrundloenService.prototype.getGrundloen = function (aar, maaned) {
        var _this = this;
        return this.socialAuthService.authState.pipe(rxOps.switchMap(function (user) {
            console.log("token", user.idToken);
            return _this.http.get(environment.aws.lambda.getGrundloen.url, {
                params: {
                    "aar": aar,
                    "maaned": maaned
                },
                headers: new HttpHeaders().set("Authorization", "Bearer " + user.idToken)
            });
        }));
        /*
        return rx.of({
          loenperiode: {
            aar: 2018,
            maaned: 9
          },
          medarbejder: {
            navn: "JensJensen",
            medarbejdernummer: 123,
            initialer: "JJE"
          },
          kontrakt: {
            basisloen: 40000,
            ninePensionProcent: 4.76
          },
          loenberegning: {
            udbetaltBeloeb: null,
            beloebInclPension: 50000,
            adhocBeloeb: null,
            ninePensionBeloeb: null
          },
          adhocLoen: [
            {
              kommentar: "Lønjustering",
              beloeb: 10000
            }
          ]
        });
        */
    };
    GrundloenService.ngInjectableDef = i0.defineInjectable({ factory: function GrundloenService_Factory() { return new GrundloenService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: GrundloenService, providedIn: "root" });
    return GrundloenService;
}());
export { GrundloenService };
