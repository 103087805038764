import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SocialLoginModule, AuthServiceConfig } from "angular5-social-login";

import { AppComponent } from './app.component';
import { BonusViewComponent } from './bonus/bonus-view/bonus-view.component';
import { StartPageComponent } from './start-page/start-page.component';
import { GrundloenViewComponent } from './grundloen/grundloen-view/grundloen-view.component';
import { PensionViewComponent } from "./pension/pension-view/pension-view.component";

import { appRoutes } from "./app.routes";
import { socialLoginConfig } from "./angular5-social-login.conf";
import { InfoIconComponent } from './shared/info-icon/info-icon.component';
import { DifferenceIconComponent } from './shared/difference-icon/difference-icon.component';
import { MonthNamePipe } from './shared/month-name.pipe';

@NgModule({
  declarations: [
    AppComponent,
    BonusViewComponent,
    StartPageComponent,
    GrundloenViewComponent,
    PensionViewComponent,
    InfoIconComponent,
    DifferenceIconComponent,
    MonthNamePipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(
      appRoutes,
      {
        //enableTracing: true // <-- debugging purposes only
      }
    ),

    NgbModule.forRoot(),
    SocialLoginModule,
  ],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'da',
    },
    {
      provide: AuthServiceConfig,
      useFactory: socialLoginConfig
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

registerLocaleData(localeDa, 'da');
