import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import { socialLoginConfig } from "./angular5-social-login.conf";
var ɵ0 = socialLoginConfig;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
registerLocaleData(localeDa, 'da');
export { ɵ0 };
