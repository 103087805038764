import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mn-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent implements OnInit {

  @Input()
  title : string = "Information";

  constructor() { }

  ngOnInit() {
  }

}
