import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as rxOps from 'rxjs/operators';
import { AuthService } from 'angular5-social-login';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BonusService {

  constructor(private http: HttpClient, private socialAuthService: AuthService) {
  }

  getLatestBonus() : Observable<any> {
    return this.getBonus("", "");
  }

  getBonus(aar, maaned) : Observable<any> {
    return this.socialAuthService.authState.pipe(rxOps.switchMap(user => {
      console.log("token", user.idToken);
      return this.http.get(environment.aws.lambda.getBonus.url, {
        params: {
          "aar": aar,
          "maaned": maaned
        },
        headers: new HttpHeaders().set("Authorization", "Bearer " + user.idToken)
      });
    }));
  }

}
