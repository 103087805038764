import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as rxOps from 'rxjs/operators';
import { AuthService } from 'angular5-social-login';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular5-social-login";
var BonusService = /** @class */ (function () {
    function BonusService(http, socialAuthService) {
        this.http = http;
        this.socialAuthService = socialAuthService;
    }
    BonusService.prototype.getLatestBonus = function () {
        return this.getBonus("", "");
    };
    BonusService.prototype.getBonus = function (aar, maaned) {
        var _this = this;
        return this.socialAuthService.authState.pipe(rxOps.switchMap(function (user) {
            console.log("token", user.idToken);
            return _this.http.get(environment.aws.lambda.getBonus.url, {
                params: {
                    "aar": aar,
                    "maaned": maaned
                },
                headers: new HttpHeaders().set("Authorization", "Bearer " + user.idToken)
            });
        }));
    };
    BonusService.ngInjectableDef = i0.defineInjectable({ factory: function BonusService_Factory() { return new BonusService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: BonusService, providedIn: "root" });
    return BonusService;
}());
export { BonusService };
