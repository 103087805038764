import { Component, OnInit, Input } from '@angular/core';
import { BonusService } from '../bonus.service';

@Component({
  selector: 'mn-bonus-view',
  templateUrl: './bonus-view.component.html',
  styleUrls: ['./bonus-view.component.scss']
})
export class BonusViewComponent implements OnInit {

  bonus: any;
  error: string;
  loading: boolean = false;
  latestAar: number;
  latestMaaned: number;
  aar: number;
  maaned: number;

  constructor(private bonusService: BonusService) {
  }

  ngOnInit() {
    this.error = "";
    this.loading = true;
    this.bonusService.getLatestBonus().subscribe(
      data => {
        console.info("Success loading data", data);
        this.bonus = data;
        this.latestAar = this.bonus.optjeningsperiode.aar;
        this.latestMaaned = this.bonus.optjeningsperiode.maaned;
        this.aar = this.latestAar;
        this.maaned = this.latestMaaned;
        this.loading = false;
      },
      err => {
        console.error("Error loading data", err);
        if (err.status === 404) {
          this.error = "Ingen data fundet";
        } else {
          this.error = "Failed";
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
        console.log('Done loading bonus', this.bonus);
      }
    );
  }

  getMaanedList() {
    if (this.aar === this.latestAar) {
      return Array.from(new Array(this.latestMaaned), (val, index) => index+1);
    }
    return Array.from(new Array(12), (val, index) => index+1);
  }

  getArrList() {
    return Array.from(new Array((this.latestAar || new Date().getFullYear()) - 2008 + 1), (val, index) => 2008 + index);
  }

  hentData() {
    this.error = "";
    this.loading = true;
    this.bonusService.getBonus(this.aar, this.maaned).subscribe(
      data => {
        console.info("Success loading data", data);
        this.bonus = data;
        this.loading = false;
      },
      err => {
        console.error("Error loading bonus", err, err.status, err.status === 404);
        if (err.status === 404) {
          this.error = "Ingen data fundet";
        } else {
          this.error = "Failed";
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
        console.log('Done loading bonus', this.bonus);
      }
    );
  }

  toggleFolded(event) {
    event.currentTarget.parentElement.classList.toggle("bonus-unfolded");
    event.currentTarget.parentElement.querySelectorAll(".bonus-unfolded").forEach(e => e.classList.remove("bonus-unfolded"));
  }

  getBonustimegraensenedsaettendeForMaaned() {
    if (this.bonus.kontrakt.bonusgraensenedsaettelsesmax === 0) {
      // Lidt underligt, men hvis max er 0 betyder det at der ikke er nogen, så vi laver lige et lille hack her
      return this.getBonustimegraensenedsaettende();
    } else if (this.getBonustimegraensenedsaettende() > this.bonus.kontrakt.bonusgraensenedsaettelsesmax) {
      return this.bonus.kontrakt.bonusgraensenedsaettelsesmax;
    } else {
      return this.getBonustimegraensenedsaettende();
    }
  }

  getBonustimegraensenedsaettende() {
    return this.bonus.bonusgraensenedsaettendeOpgaver.map(opgave => opgave.timer).reduce((acc, cur) => {
      return acc + cur;
    }, 0);
  }

  getBonustimegraenseForMaaned() {
    const temp = this.bonus.kontrakt.bonusgraense + this.getAdhocBonusgraensejustering() - this.getBonustimegraensenedsaettendeForMaaned();
    if (temp < 0) {
      return 0;
    } else {
      return temp;
    }
  }

  getBonusoptjenendeTimer() {
    return this.bonus.bonusoptjenendeOpgaver.map(opgave => opgave.timer).reduce((acc, cur) => {
      return acc + cur;
    }, 0);
  }

  getBonusudbetalendeTimer() {
    const temp = this.getBonusoptjenendeTimer() - this.getBonustimegraenseForMaaned();
    if (temp < 0) {
      return 0;
    } else {
      return temp;
    }
  }

  getTotalBonusoptjening() {
    return this.bonus.bonusoptjenendeOpgaver.map(opgave => opgave.totalpris).reduce((acc, cur) => {
      return acc + cur;
    }, 0);
  }

  getGennemsnitligBonustimepris() {
    return this.getTotalBonusoptjening() / this.getBonusoptjenendeTimer();
  }

  getUdbetaltTimebonus() {
    return this.getGennemsnitligBonustimepris() * this.getBonusudbetalendeTimer() * (this.bonus.kontrakt.bonusprocent / 100);
  }

  getUdbetaltAdhocbonus() {
    return this.bonus.adhocbonuser.map(bonus => bonus.beloeb).reduce((acc, cur) => {
      return acc + cur;
    }, 0);
  }

  getUdbetaltBonus() {
    return this.getUdbetaltTimebonus() + this.getUdbetaltAdhocbonus();
  }

  getAndreTimer() {
    return this.bonus.andreOpgaver.map(opgave => opgave.timer).reduce((acc, cur) => {
      return acc + cur;
    }, 0);
  }

  getAdhocBonusgraensejustering() {
    return this.bonus.adhocbonusgraensejusteringer.map(justering => justering.timer).reduce((acc, cur) => {
      return acc + cur;
    }, 0);
  }

}
