/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap/popover/popover";
import * as i3 from "@ng-bootstrap/ng-bootstrap/popover/popover-config";
import * as i4 from "./info-icon.component";
var styles_InfoIconComponent = [i0.styles];
var RenderType_InfoIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoIconComponent, data: {} });
export { RenderType_InfoIconComponent as RenderType_InfoIconComponent };
function View_InfoIconComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_InfoIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["popContent", 2]], null, 0, null, View_InfoIconComponent_1)), (_l()(), i1.ɵeld(1, 16777216, null, null, 2, "button", [["class", "info-icon"], ["triggers", "mouseenter:mouseleave"], ["type", "button"]], null, null, null, null, null)), i1.ɵdid(2, 737280, null, 0, i2.NgbPopover, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i3.NgbPopoverConfig, i1.NgZone], { ngbPopover: [0, "ngbPopover"], popoverTitle: [1, "popoverTitle"], triggers: [2, "triggers"] }, null), (_l()(), i1.ɵted(3, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.title, ""); var currVal_2 = "mouseenter:mouseleave"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.title; _ck(_v, 3, 0, currVal_3); }); }
export function View_InfoIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mn-info-icon", [], null, null, null, View_InfoIconComponent_0, RenderType_InfoIconComponent)), i1.ɵdid(1, 114688, null, 0, i4.InfoIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoIconComponentNgFactory = i1.ɵccf("mn-info-icon", i4.InfoIconComponent, View_InfoIconComponent_Host_0, { title: "title" }, {}, ["*"]);
export { InfoIconComponentNgFactory as InfoIconComponentNgFactory };
